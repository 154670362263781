<template>
  <div class="nav-drawer">
    <!-- <v-btn
      fab
      x-small
      color="ap-light-blue"
      class="pane-open ap-white--text"
      :class="{ mini: menuOpen }"
      @click="togglePane()"
    >
      <v-icon v-if="menuOpen"> $mdi-chevron-double-right </v-icon>
      <v-icon v-else> $mdi-chevron-double-left </v-icon>
    </v-btn> -->
    <v-navigation-drawer
      v-model="showTheSidebar"
      app
      color="primary"
      enable-resize-watcher
      :mini-variant="menuOpen"
      :permanent="!$vuetify.breakpoint.xsOnly"
      stateless
      hide-overlay
    >
      <router-link to="/">
        <v-img
          class="ma-4"
          :max-height="26"
          :max-width="154"
          :min-height="24"
          :src="getLogo()"
        />
      </router-link>

      <v-list dense nav class="mt-16 pa-0">
        <v-list-item
          v-for="item in filterAllowedItems(listItems)"
          :key="item.title"
          :to="item.path"
          class="ap-white--text"
          @click="collapseGroupedList()"
        >
          <v-list-item-icon :class="{ 'mx-2': menuOpen }">
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ap-white--text">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>

        <template v-if="!menuOpen">
          <v-list-group
            v-for="groupedItem in filterAllowedItems(groupedListItems)"
            :key="groupedItem.title"
            v-model="groupedItem.active"
            append-icon="$mdi-menu-down"
            :prepend-icon="groupedItem.icon"
            class="primary"
            :class="{ 'active-group': isGroupActive(groupedItem) }"
            no-action
          >
            <template #activator>
              <v-list-item-content @click="navigateTo(groupedItem.path)">
                <v-list-item-title class="ap-white--text">
                  {{ groupedItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="item in groupedItem.subItems"
              :key="item.title"
              :to="item.path"
              class="ap-white--text"
            >
              <v-list-item-title class="ap-white--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>

        <template v-else>
          <v-list-item
            v-for="item in filterAllowedItems(groupedListItems)"
            :key="item.title"
            :to="item.path"
            class="ap-white--text"
          >
            <v-list-item-icon class="mx-2">
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ap-white--text">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>

      <v-divider class="mx-2 separator" />

      <template #append>
        <v-list class="ap-white--text pa-0" dense nav>
          <v-list-item-group>
            <v-list-item
              v-for="item in bottomListItems"
              :key="item.title"
              :to="item.path"
              class="ap-white--text"
            >
              <v-list-item-icon class="mx-2">
                <v-icon class="ap-white--text" v-text="item.icon" />
              </v-list-item-icon>
              <v-list-item-title class="ap-white--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="signOut">
              <v-list-item-icon class="mx-2">
                <v-icon class="ap-white--text" v-text="'$mdi-power'" />
              </v-list-item-icon>
              <v-list-item-title class="ap-white--text">
                Logout
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  data: function () {
    return {
      showTheSidebar: true,
      listItems: [
        {
          title: 'User Management',
          icon: '$mdi-speedometer',
          path: '/admin/users',
          necessaryPermissions: ['admin.users.get'],
        },
        {
          title: 'Permissions',
          icon: '$mdi-account-cog-outline',
          path: '/admin/roles',
          necessaryPermissions: ['admin.roles.get'],
        },
      ],
      groupedListItems: [
        {
          title: 'Business Rules',
          icon: '$mdi-clipboard-check-outline',
          path: '/admin/business-rules',
          necessaryPermissions: ['admin.roles.get'],
          active: false,
          subItems: [
            {
              title: 'Block On Entry',
              path: '/admin/business-rules/block-on-entry',
            },
            {
              title: 'Detect & Merge',
              path: '/admin/business-rules/detect-and-merge',
            },
            {
              title: 'Validation',
              path: '/admin/business-rules/validation',
            },
            {
              title: 'Mass Update',
              path: '/admin/business-rules/mass-update',
            },
          ],
        },
        {
          title: 'General Settings',
          icon: '$mdi-tune',
          path: '/admin/general-settings',
          necessaryPermissions: ['admin.roles.get'],
          active: false,
          subItems: [
            {
              title: 'Setup',
              path: '/admin/general-settings/setup',
            },
            {
              title: 'Governance Rules',
              path: '/admin/general-settings/governance-rules',
            },
            {
              title: 'Notifications',
              path: '/admin/general-settings/notifications',
            },
          ],
        },
      ],
      bottomListItems: [
        {
          title: 'My profile',
          path: '/admin/account',
          icon: '$mdi-account',
          necessaryPermissions: ['admin.accounts.get'],
        },
        {
          title: 'Security',
          path: '/admin/security',
          icon: '$mdi-guitar-pick-outline',
          necessaryPermissions: ['admin.security.get'],
        },
        {
          title: 'Help',
          path: '/admin/help',
          icon: '$mdi-help-circle-outline',
          necessaryPermissions: ['admin.help.get'],
        },
      ],
      menuOpen: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['permittedActions']),
    showAdminLink() {
      for (const actionName of this.permittedActions) {
        if (actionName.startsWith('admin')) {
          return true
        }
      }

      return false
    },
    getActiveMenuClass() {
      if (!this.menuOpen) {
        return 'ml-6 px-2 mx-2'
      }

      return 'mx-auto'
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    togglePane() {
      this.menuOpen = !this.menuOpen
    },
    getLogo() {
      if (!this.menuOpen) {
        return require('@/assets/images/fullAPLogo.jpg')
      }

      return require('@/assets/images/aplogo.png')
    },
    filterAllowedItems(items) {
      if (!this.user) return false

      const allowedItems = []

      for (const item of items) {
        let userHasNecessaryPermissions = true

        for (const requiredPermission of item.necessaryPermissions) {
          if (!this.permittedActions.includes(requiredPermission)) {
            userHasNecessaryPermissions = false
          }
        }
        if (userHasNecessaryPermissions) {
          allowedItems.push(item)
        }
      }

      return allowedItems
    },
    navigateTo(route) {
      if (!route || this.$route.path === route) return

      this.$router.push(route)
    },
    collapseGroupedList() {
      this.groupedListItems.forEach((el) => {
        el.active = false
      })
    },
    isGroupActive(item) {
      return this.$route.path.includes(item.path)
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-drawer {
  .pane-open {
    position: fixed;
    left: 240px;
    z-index: 200;
    top: 60px;

    &.mini {
      left: 36px;
    }
  }

  .separator {
    border-color: #fff;
    opacity: 0.2;
  }

  ::v-deep .v-list-group.active-group {
    .v-list-group__header.v-list-item::before {
      opacity: 0.12 !important;
      color: #fff;
    }
  }
}

::v-deep .v-list-group__header,
a.v-list-item.v-list-item--link {
  .theme--light.v-icon {
    color: #fff;
  }
}

::v-deep .v-list-group {
  &.v-list-group--active {
    background-color: var(--v-ap-dark-blue-base);

    .alert-sidebar {
      color: var(--v-ap-light-blue-base);
    }

    .v-list-group__header,
    a.v-list-item.v-item--active.v-list-item--link {
      .theme--light.v-icon {
        color: var(--v-ap-light-blue-base);
      }
    }
  }
}

.theme--light {
  &.v-list {
    padding: 8px 0;

    .v-list-item {
      min-height: 32px;
      padding: 0 8px;
    }
  }
}

.v-list-item--dense,
.v-list--dense {
  .v-list-item__title,
  .v-list-item__subtitle,
  .v-list-item .v-list-item__title,
  .v-list-item .v-list-item__subtitle {
    font-size: 13px;
    font-weight: 400;
  }
}
</style>
