<template>
  <v-layout>
    <AdminSidebar />
    <v-main>
      <!-- <v-container fluid> -->
      <router-view />
      <!-- </v-container> -->
    </v-main>
  </v-layout>
</template>

<script>
import AdminSidebar from './components/AdminSidebar'

export default {
  components: {
    AdminSidebar,
  },
}
</script>
